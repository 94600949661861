import { FC, Key, useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useApp } from '@/utils/useapp';
import { useForm } from 'antd/lib/form/Form';
import moment from 'moment';
import {
  destinationAccesorials,
  DimensionUnit,
  genItemsWithClass,
  packageingTypes,
  pickupAccesorials,
  QuoteItem,
  WeightUnit,
} from '@/utils/freight';
import { Quote } from '../quotes';
import { history } from 'umi';
import { UserSyncSelect } from '@/components/UserSyncSelect';
import ShipmentDrawer from './ShipmentDrawer';
import { AddressTypeRadio } from './address-type-radio';
import { LTLAddressType, QuoteBusinessStatus } from '../constants';
import { RateTable } from './RateTable';
import { Gap } from '@/components/Gap';
import QuickLoad from './QuickLoad';
import Title from 'antd/lib/typography/Title';
import Aggregator from './Aggregator';
import { AIfillBtn } from './AIFillButton';

const { Text } = Typography;

const UNKNOW_STATUS = '-/-';
const FAST_INTERVAL = 1000;
const SLOW_INTERVAL = 3000;

interface Props {
  model?: Quote;
  isCopy?: boolean;
  onCreated: () => void;
  onClose: () => void;
}

interface UpdatedRate {
  rateId: number;
  newRate: number;
}

export interface City {
  id: number;
  name: string;
  state: string;
}

export interface Rate {
  id: number;
  quote_rate: number;
  rate: number;
  quote_id: number;
  vendor_rate_id: string;
  carrier_name: string;
  carrier_code: string;
  transit_days: number;
  expiration_date: string;
  estimated_pickup_date: string;
  estimated_delivery_date: string;
  services: { name: string; description: string }[];
  errors: string[];
  selected: boolean;
  vendor: {
    id: number;
    name: string;
    key: string;
  };
}

export interface QuoteDetail {
  id: number;
  pickup_date: string;
  pickup_zipcode: string;
  destination_zipcode: string;
  pickup_accesorials: string[];
  destination_accesorials: string[];
  items: QuoteItem[];
}

export interface ShipmentParams {
  rate: Rate;
  quoteFields: any;
}

const QuoteForm: FC<Props> = ({
  model,
  isCopy = false,
  onCreated,
  onClose,
}) => {
  const app = useApp();
  const [form] = useForm();

  const quoteIdRef = useRef(model?.id);
  const timerIdRef = useRef<NodeJS.Timer | null>();

  const [packageTypes] = useState(
    packageingTypes.map((item) => ({
      value: item,
      label: item,
    })),
  );
  const [pickupAddressType, setPickupAddressType] = useState<string>(
    LTLAddressType.BUSSINESS,
  );
  const [destinationAddressType, setDestinationAddressType] = useState<string>(
    LTLAddressType.BUSSINESS,
  );

  const [quoteChanged, setQuoteChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ratesLoading, setRatesLoading] = useState(false);
  const [shipformVisible, setShipformVisible] = useState(false);
  const [selectedRates, setSelectedRates] = useState<Key[]>([]);
  const [updatedRates, setUpdatedRates] = useState<UpdatedRate[]>([]);
  const [items, setItems] = useState<QuoteItem[]>([]);
  const [status, setStatus] = useState<string>(UNKNOW_STATUS);
  const [rates, setRates] = useState<Rate[]>();
  const [quoteErrors, setQuoteErrors] = useState<string[]>();
  const [rateInterval, setRateInterval] = useState<number>(FAST_INTERVAL);

  const [shipParams, setShipParams] = useState<ShipmentParams>();
  const [quote, setQuote] = useState<Quote>();
  const [quoteValidHours] = useState<number>(24);

  const [pickupCityList, setPickupCityList] = useState<City[]>([]);
  const [destinationCityList, setDestinationCityList] = useState<City[]>([]);

  const [holidays, setHolidays] = useState<string[]>();
  const [holidaysLoading, setHolidaysLoading] = useState<boolean>(false);
  const [isInit, setIsInit] = useState<boolean>(true);
  const [formWaringText, setFormWaringText] = useState<string>();

  const disableChange =
    quote && !isCopy
      ? quote.business_status >= QuoteBusinessStatus.SUBMITED
      : false;

  const fectHolidays = async () => {
    setHolidaysLoading(true);
    try {
      const resp = await app.service.get('tools/holidays-from-now');
      setHolidays(resp.data);
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    } finally {
      setHolidaysLoading(false);
    }
  };

  const fetchQuote = async () => {
    if (quoteIdRef.current === undefined) {
      return;
    }

    setLoading(true);
    try {
      const resp = await app.service.get(`tl/ltl/quotes/${quoteIdRef.current}`);
      form.setFieldsValue({
        ...resp.data,
        pickup_accessorials: resp.data.pickup_accessorials ?? [],
        destination_accessorials: resp.data.destination_accessorials ?? [],
      });

      if (isCopy) {
        form.setFieldsValue({
          id: undefined,
          status: undefined,
          pickup_date: isInit ? undefined : resp.data.pickup_date,
          // business_status: QuoteBusinessStatus.DRAFT,
        });
        isInit && setIsInit(false);
      } else {
        setStatus(resp.data.status);
        setQuote(resp.data);
        setItems(resp.data.items);
        setQuoteErrors(resp.data.errors);
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }

    setLoading(false);
  };

  const fetchPickupCityList = async () => {
    try {
      const resp = await app.service.get(
        `tl/tools/citylist/${form.getFieldValue('pickup_zipcode')}`,
      );
      if (resp?.data) {
        setPickupCityList(resp.data);
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const fetchDestinationCityList = async () => {
    try {
      const resp = await app.service.get(
        `tl/tools/citylist/${form.getFieldValue('destination_zipcode')}`,
      );
      if (resp?.data) {
        setDestinationCityList(resp.data);
      }
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const done = () => {
    if (status === UNKNOW_STATUS) {
      return -1;
    }
    return parseInt(status.split('/')[0]);
  };

  const total = () => {
    if (status === UNKNOW_STATUS) {
      return -1;
    }
    return parseInt(status.split('/')[1]);
  };

  const fetchRates = async () => {
    try {
      const resp = await app.service.get(
        `tl/ltl/quotes/${quoteIdRef.current}/rates`,
      );

      setRates(resp.data.rates);
      if (resp.data.rates.length > 0) {
        setRateInterval(SLOW_INTERVAL);
      }
      setStatus(resp.data.quoteStatus);
      setQuoteErrors(resp.data.errors);
      return resp;
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const intervalFetchRates = async () => {
    setRatesLoading(true);
    try {
      await fetchRates();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const stopPolling = () => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
    }
  };

  const startPolling = () => {
    if (!timerIdRef.current) {
      timerIdRef.current = setInterval(intervalFetchRates, rateInterval);
    }
  };

  const restartPolling = () => {
    stopPolling();
    startPolling();
  };

  const resetStatus = () => {
    setStatus(UNKNOW_STATUS);
  };

  const checkFormData = () => {
    if (
      (form.getFieldValue('pickup_accessorials')?.includes('liftgate') ||
        form.getFieldValue('destination_accessorials')?.includes('liftgate')) &&
      form.getFieldValue('items').some((item: QuoteItem) => {
        const isTooBig =
          item.dimension_unit === DimensionUnit.CM
            ? item.length >= 210 || item.width >= 210 || item.height >= 239
            : item.length >= 83 || item.width >= 83 || item.height >= 94;
        const isOverWeight =
          item.weight_unit === WeightUnit.LBS
            ? item.total_weight / item.units >= 1999
            : item.total_weight / item.units >= 907;
        return isTooBig || isOverWeight;
      })
    ) {
      setFormWaringText(
        'The shipment exceeds the size limits of the Liftgate service, is more than 83 inches long or wide, more than 94 inches high, and weighs more than 1,999 pounds per pallet. Service providers may not be able to provide services or may charge additional fees',
      );
    } else {
      setFormWaringText('');
    }

    if (
      !pickupCityList
        .map((item) => item.id)
        .includes(form.getFieldValue('pickup_city_id'))
    ) {
      message.error(
        'Pickup city is not match with origin zipcode.Please reselect the pickup city.',
      );
      return false;
    }

    if (
      !destinationCityList
        .map((item) => item.id)
        .includes(form.getFieldValue('destination_city_id'))
    ) {
      message.error(
        'Destination city is not match with destination zipcode.Please reselect the destination city.',
      );
      return false;
    }
    return true;
  };

  const handleStartGetRates = async () => {
    let values = null;
    try {
      values = await form.validateFields();
    } catch (error) {
      return;
    }

    const result = checkFormData();
    if (!result) {
      return;
    }

    setRatesLoading(true);
    resetStatus();
    setRateInterval(FAST_INTERVAL);

    const data = {
      ...values,
      items: genItemsWithClass(values.items),
    };
    let resp = null;
    try {
      if (!isCopy && quoteIdRef.current) {
        resp = await app.service.put(
          `tl/ltl/quotes/${quoteIdRef.current}/update-with-quote`,
          {
            data,
          },
        );
      } else {
        resp = await app.service.post('tl/ltl/quotes', {
          data,
        });
      }
      quoteIdRef.current = resp.data.id;
      onCreated && onCreated();
      startPolling();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
      setRatesLoading(false);
    }
  };

  const handelSave = async (values: any) => {
    const result = checkFormData();
    if (!result) {
      return;
    }

    let resp = null;
    const data = {
      ...values,
      items: genItemsWithClass(values.items),
      selectedRates,
      updatedRates,
    };
    try {
      if (quoteIdRef.current) {
        resp = await app.service.put(`tl/ltl/quotes/${quoteIdRef.current}`, {
          data,
        });
      } else {
        resp = await app.service.post('tl/ltl/quotes', {
          data,
        });
      }

      quoteIdRef.current = resp.data.id;
      message.success('Saved');
      onCreated && onCreated();
    } catch (e: any) {
      message.error(e.data?.message || e.data?.error);
    }
  };

  const resetItems = (items?: QuoteItem[]) => {
    const defaultItems: QuoteItem[] = [
      {
        units: 1,
        package_type: 'Pallet',
        pieces: 1,
        length: 48,
        width: 40,
        height: 0,
        total_weight: 0,
        weight_unit: WeightUnit.LBS,
        dimension_unit: DimensionUnit.IN,
        class: '50',
      } as QuoteItem,
    ];
    form.setFieldsValue({
      items: items || defaultItems,
    });
    setItems(items || defaultItems);
    setQuoteChanged(true);
  };

  const onFormValueChange = (changedValues: any, allValues: any) => {
    setQuoteChanged(true);
    if (changedValues.items) {
      setItems(allValues.items);
    }

    if (changedValues.pickup_zipcode) {
      form.setFieldValue('pickup_city_id', null);
      fetchPickupCityList();
    }

    if (changedValues.destination_zipcode) {
      form.setFieldValue('destination_city_id', null);
      fetchDestinationCityList();
    }

    if (changedValues.pickup_address_type) {
      setPickupAddressType(changedValues.pickup_address_type);
      if (
        (changedValues.pickup_address_type === LTLAddressType.RESIDENTIAL ||
          changedValues.pickup_address_type === LTLAddressType.TRADESHOW) &&
        !form.getFieldValue('pickup_accessorials')?.includes('liftgate')
      ) {
        form.setFieldValue('pickup_accessorials', [
          ...(form.getFieldValue('pickup_accessorials') ?? []),
          'liftgate',
        ]);
      }
    }

    if (changedValues.destination_address_type) {
      setDestinationAddressType(changedValues.destination_address_type);
      if (
        (changedValues.destination_address_type ===
          LTLAddressType.RESIDENTIAL ||
          changedValues.destination_address_type ===
            LTLAddressType.TRADESHOW) &&
        !form.getFieldValue('destination_accessorials')?.includes('liftgate')
      ) {
        form.setFieldValue('destination_accessorials', [
          ...(form.getFieldValue('destination_accessorials') ?? []),
          'liftgate',
        ]);
      }
    }
  };

  const handleSelect = async (record: Rate) => {
    const quoteFields = await form.validateFields();
    setShipParams({
      rate: record,
      quoteFields: {
        ...quoteFields,
        items: genItemsWithClass(quoteFields.items),
      },
    });
    setShipformVisible(true);
  };

  useEffect(() => {
    if (quoteIdRef.current && model) {
      form.resetFields();
      fetchQuote();
      !isCopy && fetchRates();
    }
  }, [quoteIdRef.current, isCopy]);

  useEffect(() => {
    if (form.getFieldValue('pickup_zipcode')) {
      fetchPickupCityList();
    }
  }, [form.getFieldValue('pickup_zipcode')]);

  useEffect(() => {
    if (form.getFieldValue('destination_zipcode')) {
      fetchDestinationCityList();
    }
  }, [form.getFieldValue('destination_zipcode')]);

  useEffect(() => {
    return () => {
      if (timerIdRef.current) {
        clearInterval(timerIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fectHolidays();
  }, []);

  useEffect(() => {
    setSelectedRates(
      rates?.filter((rate) => rate.selected).map((r) => r.id) ?? [],
    );
  }, [rates]);

  useEffect(() => {
    if (quoteIdRef.current) {
      return;
    }
    resetItems();
  }, []);

  useEffect(() => {
    if (done() === total() && total() != -1) {
      setRatesLoading(false);
      stopPolling();
      setQuoteChanged(false);
    }
    if (done() < total()) {
      setRatesLoading(true);
      startPolling();
    }
  }, [status]);

  useEffect(() => {
    if (done() < total()) {
      setRatesLoading(true);
      restartPolling();
    }
  }, [rateInterval]);

  return (
    <>
      <Form
        layout="vertical"
        disabled={loading}
        onValuesChange={onFormValueChange}
        onFinish={handelSave}
        initialValues={{}}
        form={form}
      >
        <Row>
          <Space direction="vertical">
            <Space>
              <AIfillBtn
                form={form}
                setItems={setItems}
                setPickupCityList={setPickupCityList}
                setDestinationCityList={setDestinationCityList}
              />
              <Text type="warning">
                The quotation is valid for {quoteValidHours} hours.
              </Text>
            </Space>

            <Form.Item
              name="user_id"
              label="Customer"
              rules={[{ required: true }]}
            >
              <UserSyncSelect style={{ width: '240px' }} />
            </Form.Item>
          </Space>
        </Row>
        <Row gutter={64}>
          <Col span={12}>
            <Space.Compact
              direction="vertical"
              style={{
                width: '100%',
              }}
            >
              <Space>
                <Form.Item
                  label="Origin Zipcode"
                  name="pickup_zipcode"
                  rules={[{ required: true }]}
                >
                  <Input
                    disabled={disableChange}
                    placeholder="Enter pickup zipcode."
                  />
                </Form.Item>
                <Form.Item
                  label="Origin City"
                  name="pickup_city_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    disabled={disableChange}
                    placeholder="Please select city"
                    options={pickupCityList.map((item) => ({
                      value: item.id,
                      label: `${item.name}, ${item.state}`,
                    }))}
                  />
                </Form.Item>
              </Space>
              <Form.Item
                name="pickup_address_type"
                initialValue={LTLAddressType.BUSSINESS}
                rules={[{ required: true }]}
              >
                <AddressTypeRadio disabled={disableChange} />
              </Form.Item>
              <Form.Item
                name="pickup_accessorials"
                label="Select accessorial services"
              >
                <Select
                  mode="multiple"
                  disabled={disableChange}
                  allowClear
                  placeholder="Please select accessorial services"
                  options={pickupAccesorials.map((item) => {
                    return {
                      value: item.key,
                      label: item.name,
                      disabled:
                        pickupAddressType === LTLAddressType.RESIDENTIAL &&
                        item.key === 'liftgate',
                    };
                  })}
                />
              </Form.Item>
            </Space.Compact>
          </Col>
          <Col span={12}>
            <Space.Compact
              direction="vertical"
              style={{
                width: '100%',
              }}
            >
              <Space>
                <Form.Item
                  label="Destination Zipcode"
                  name="destination_zipcode"
                  rules={[{ required: true }]}
                >
                  <Input
                    disabled={disableChange}
                    placeholder="Enter destination zipcode."
                  />
                </Form.Item>
                <Form.Item
                  label="Destination City"
                  name="destination_city_id"
                  rules={[{ required: true }]}
                >
                  <Select
                    allowClear
                    disabled={disableChange}
                    placeholder="Please select city"
                    options={destinationCityList.map((item) => ({
                      value: item.id,
                      label: `${item.name}, ${item.state}`,
                    }))}
                  />
                </Form.Item>
              </Space>
              <Form.Item
                name="destination_address_type"
                initialValue={LTLAddressType.BUSSINESS}
                rules={[{ required: true }]}
              >
                <AddressTypeRadio disabled={disableChange} />
              </Form.Item>
              <Form.Item
                name="destination_accessorials"
                label="Select destination accessorial services"
              >
                <Select
                  mode="multiple"
                  disabled={disableChange}
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select accessorial services"
                  options={destinationAccesorials.map((item) => ({
                    value: item.key,
                    label: item.name,
                    disabled:
                      destinationAddressType === LTLAddressType.RESIDENTIAL &&
                      item.key === 'liftgate',
                  }))}
                />
              </Form.Item>
            </Space.Compact>
          </Col>
        </Row>
        <Divider />
        <Space direction="vertical">
          <Space align="baseline">
            <Title level={5}>Shipment items</Title>
          </Space>
          <QuickLoad
            disabled={disableChange}
            handleQuickLoad={(item: QuoteItem) => {
              resetItems([item]);
            }}
          />
        </Space>

        <Divider />
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <Row gutter={8}>
              {fields.map(({ key, name, ...restField }) => (
                <Col key={key} span={24}>
                  <Space
                    size="small"
                    style={{ display: 'flex' }}
                    align={name > 0 ? 'baseline' : 'center'}
                  >
                    <Button
                      type="text"
                      disabled={disableChange}
                      icon={<MinusCircleOutlined />}
                      onClick={() => {
                        remove(name);
                      }}
                    ></Button>
                    <Button
                      disabled={disableChange}
                      onClick={() => {
                        const currentItem = form.getFieldValue(['items', name]);
                        add({
                          units: currentItem.units,
                          package_type: currentItem.package_type,
                          pieces: currentItem.pieces,
                          length: currentItem.length,
                          width: currentItem.width,
                          height: currentItem.height,
                          total_weight: currentItem.total_weight,
                          weight_unit: currentItem.weight_unit,
                          dimension_unit: currentItem.dimension_unit,
                        });
                      }}
                    >
                      Copy
                    </Button>
                    <Form.Item
                      {...restField}
                      name={[name, 'units']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Units'}
                    >
                      <InputNumber
                        disabled={disableChange}
                        min={1}
                        placeholder="Units"
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'package_type']}
                      label={name > 0 ? '' : 'Type'}
                      initialValue={'Pallet'}
                      rules={[{ required: true }]}
                    >
                      <Select
                        disabled={disableChange}
                        placeholder="Select package type."
                        options={packageTypes}
                        style={{
                          width: 200,
                        }}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'description']}
                      label={name > 0 ? '' : 'Commondity Description'}
                      rules={[{ required: true }]}
                      style={{
                        width: 200,
                      }}
                    >
                      <Input
                        disabled={disableChange}
                        placeholder="Enter description."
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'nmfc']}
                      label={name > 0 ? '' : 'NMFC'}
                      style={{
                        width: 200,
                      }}
                    >
                      <Input
                        disabled={disableChange}
                        placeholder="Enter description."
                      />
                    </Form.Item>

                    <Form.Item label={name > 0 ? '' : 'Dimensions'}>
                      <Space.Compact>
                        <Form.Item
                          {...restField}
                          name={[name, 'length']}
                          initialValue={1}
                          noStyle
                          rules={[
                            { required: true, message: 'Missing length' },
                          ]}
                        >
                          <InputNumber
                            disabled={disableChange}
                            min={1}
                            placeholder="Length"
                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'width']}
                          initialValue={1}
                          noStyle
                          rules={[{ required: true, message: 'Missing width' }]}
                        >
                          <InputNumber
                            disabled={disableChange}
                            min={1}
                            placeholder="Width"
                            style={{
                              width: '100%',
                            }}
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'height']}
                          initialValue={1}
                          noStyle
                          rules={[
                            { required: true, message: 'Missing height' },
                          ]}
                        >
                          <InputNumber
                            disabled={disableChange}
                            min={1}
                            placeholder="Height"
                            style={{
                              width: '100%',
                            }}
                            addonAfter={
                              <Form.Item
                                {...restField}
                                name={[name, 'dimension_unit']}
                                initialValue={'in'}
                                noStyle
                                rules={[{ required: true }]}
                              >
                                <Select
                                  disabled={disableChange}
                                  style={{
                                    width: 64,
                                  }}
                                  options={[
                                    {
                                      value: 'in',
                                      label: 'in',
                                    },
                                    {
                                      value: 'cm',
                                      label: 'cm',
                                    },
                                  ]}
                                />
                              </Form.Item>
                            }
                          />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, 'total_weight']}
                      initialValue={'1'}
                      label={name > 0 ? '' : 'Total weight'}
                      rules={[
                        { required: true, message: 'Missing total weight' },
                      ]}
                    >
                      <InputNumber
                        disabled={disableChange}
                        min={1}
                        placeholder="Total weight"
                        style={{
                          width: '100%',
                        }}
                        addonAfter={
                          <Form.Item
                            {...restField}
                            name={[name, 'weight_unit']}
                            initialValue={'lbs'}
                            rules={[{ required: true }]}
                            noStyle
                          >
                            <Select
                              disabled={disableChange}
                              style={{
                                width: 64,
                              }}
                              options={[
                                {
                                  value: 'kg',
                                  label: 'kg',
                                },
                                {
                                  value: 'lbs',
                                  label: 'lbs',
                                },
                              ]}
                            />
                          </Form.Item>
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'pieces']}
                      initialValue={1}
                      label={name > 0 ? '' : 'Pieces'}
                    >
                      <InputNumber
                        disabled={disableChange}
                        min={1}
                        placeholder="Pieces"
                        style={{
                          width: '100%',
                        }}
                      />
                    </Form.Item>
                  </Space>
                </Col>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  disabled={disableChange}
                  onClick={() =>
                    add({
                      units: 1,
                      package_type: 'Pallet',
                      pieces: 1,
                      length: 48,
                      width: 40,
                      height: 0,
                      total_weight: 0,
                      weight_unit: WeightUnit.LBS,
                      dimension_unit: DimensionUnit.IN,
                    })
                  }
                  block
                  icon={<PlusOutlined />}
                >
                  Add item
                </Button>
              </Form.Item>
            </Row>
          )}
        </Form.List>
        <Divider />
        <Aggregator items={items} />
        <Divider />
        <Space direction="horizontal">
          <Form.Item
            label="Pickup Date"
            name="pickup_date"
            rules={[{ required: true }]}
            getValueFromEvent={(onChange) =>
              onChange ? moment(onChange).format('YYYY-MM-DD') : null
            }
            getValueProps={(v) => ({ value: v ? moment(v) : null })}
          >
            <DatePicker
              disabled={disableChange}
              style={{ width: '100%' }}
              disabledDate={(current) => {
                if (holidaysLoading) {
                  return false;
                }
                return (
                  (current < moment().startOf('day') ||
                    holidays?.includes(moment(current).format('YYYY-MM-DD'))) ??
                  false
                );
              }}
            />
          </Form.Item>

          <Button
            disabled={disableChange}
            type="primary"
            loading={ratesLoading}
            onClick={handleStartGetRates}
          >
            Get Rates
          </Button>
          <Text
            type={
              total() === done() && done() === -1
                ? 'secondary'
                : total() === done() && !quoteErrors?.length
                ? 'success'
                : 'warning'
            }
          >
            {`Quote Progress: ${status}`}
          </Text>
          <Text>{quote?.quote_at ?? ''}</Text>
          <Text>{`Default Margin Setting ${
            quote?.margin_percent?.toFixed(2) ?? '-'
          }%`}</Text>
          <Text type="warning">{formWaringText}</Text>
        </Space>
        <Space direction="vertical">
          {quoteErrors?.map((error, index) => (
            <Text key={index} type="danger">
              {error}
            </Text>
          ))}
        </Space>
        <Row>
          <RateTable
            rates={rates ?? []}
            selectedRates={selectedRates}
            setSelectedRates={setSelectedRates}
            disableSelect={
              (quote?.business_status === QuoteBusinessStatus.DRAFT &&
                quoteChanged) ||
              (!!quote?.business_status &&
                quote?.business_status >= QuoteBusinessStatus.BOOKED)
            }
            handleSelect={handleSelect}
            handleUpdateRate={(rateId: number, newRate: number) => {
              setUpdatedRates([
                ...updatedRates.filter((r) => r.rateId !== rateId),
                { rateId, newRate },
              ]);
            }}
          />
        </Row>
        <Gap height="16px" />
        <Row>
          <Space direction="horizontal">
            <Button
              type="primary"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            {(!model?.id ||
              isCopy ||
              quote?.business_status === QuoteBusinessStatus.DRAFT) && (
              <Button type="primary" htmlType="submit" disabled={quoteChanged}>
                Save as Draft
              </Button>
            )}
            {(!model?.id ||
              isCopy ||
              [
                QuoteBusinessStatus.DRAFT,
                QuoteBusinessStatus.SUBMITED,
                QuoteBusinessStatus.CONFIRMED,
              ].includes(
                quote?.business_status ?? QuoteBusinessStatus.EXPIRED,
              )) && (
              <Button
                type="primary"
                disabled={quoteChanged}
                onClick={async () => {
                  const formValues = await form.getFieldsValue();
                  handelSave({
                    ...formValues,
                    business_status: QuoteBusinessStatus.CONFIRMED,
                  });
                }}
              >
                Confirm
              </Button>
            )}
          </Space>
        </Row>
      </Form>

      <ShipmentDrawer
        visible={shipformVisible}
        // setVisible={setShipformVisible}
        params={shipParams}
        onSaved={(id: number) => {
          history.push('/truckload/ltl/shipments');
        }}
        onClose={() => setShipformVisible(false)}
      />
    </>
  );
};

export default QuoteForm;
