import { UserSyncSelect } from '@/components/UserSyncSelect';
import { TAccount } from '@/types';
import { useApp } from '@/utils/useapp';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import { debounce, find, set } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { useForm, useWatch } from 'antd/lib/form/Form';
import { use } from 'echarts';

interface IPrepayOrderDetailProps {
  accounts: Array<TAccount>;
  banks: Array<any>;
  onClose: (reload?: boolean) => void;
}
const FIXED = 1;
const FLOATING = 0;

const PrepayOrderDetail: FC<IPrepayOrderDetailProps> = ({ banks, onClose }) => {
  const app = useApp();

  const [submitting, setSubmitting] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [bank, setBank] = useState<number>();
  const [amount, setAmount] = useState<number>();
  const [fileList, setFileList] = useState<any[]>([]);

  const [convertResult, setConvertResult] = useState<{
    rate: number;
    cost: number;
    amount: number;
  }>({
    rate: 1,
    cost: 0,
    amount: 0,
  });

  const [form] = useForm();

  const exhcangeType = useWatch('exchange_type', form);
  const exchangeRate = useWatch('exchange_rate', form);

  const close = useCallback(() => {
    if (!wasChanged) {
      return onClose();
    }
    Modal.confirm({
      title: 'Warning',
      content: 'Are you sure you want to cancel the edited content?',
      onOk: () => onClose(),
      maskClosable: true,
    });
  }, [wasChanged]);

  const convertWithFixedRate = (amount: number | undefined) => {
    if (exhcangeType == FIXED && convertResult && amount) {
      const cost = parseFloat(
        `${bankInfo.custom_properties?.exchange_rate_cost || 0}`,
      );
      const result = amount / (+exchangeRate + +cost);
      setConvertResult({ ...convertResult, amount: result });
    }
  };

  const bankInfo = useMemo(() => {
    const info = find(banks, { id: bank });
    return info || null;
  }, [bank]);

  const handleUpload = (info: any) => {
    let fileList: any[] = [...info.fileList];

    fileList = fileList.slice(-1);

    setFileList(fileList);
    form.setFieldValue('document', info.file);
  };

  const onFinish = useCallback(async (values: any) => {
    const formData = new FormData();
    formData.append('documents[0]', values['document']);
    formData.append('user_id', values['user_id']);
    formData.append('amount', values['amount']);
    formData.append('bank', values['bank']);
    formData.append('exchange_rate', values['exchange_rate']);
    formData.append('exchange_type', values['exchange_type']);

    try {
      const result = await app.service.post('prepaidOrders', {
        data: formData,
      });
      if (result) {
        onClose(true);
        return;
      }
    } catch (e: any) {
      message.error(e.toString() || 'Failed');
    }
    setSubmitting(false);
  }, []);

  const _getExchangeRate = async (bankInfo: any, amount: number) => {
    if (form.getFieldValue('exchange_type') == FIXED) {
      convertWithFixedRate(amount);
      return;
    }

    try {
      const exchangeRate = await app.service.get(
        `prepaidOrders/exchange-rate?from=${bankInfo.currency}&to=USD`,
      );
      const cost = parseFloat(
        `${bankInfo.custom_properties?.exchange_rate_cost || 0}`,
      );
      const result = amount / (exchangeRate + cost);
      setConvertResult({
        rate: exchangeRate,
        cost,
        amount: result,
      });

      form.setFieldsValue({
        exchange_rate: exchangeRate,
      });
    } catch (e: any) {
      message.error(
        e.toString() || 'Failed to obtain real-time exchange rate.',
      );
    }
  };

  const debouncedGetExchangeRate = useCallback(
    debounce(_getExchangeRate, 200),
    [convertResult, exhcangeType],
  );

  useEffect(() => {
    if (bankInfo && bankInfo.currency !== 'USD') {
      if (!amount || amount <= 0) {
        setConvertResult({ rate: 1, cost: 0, amount: 0 });
        return;
      }
      debouncedGetExchangeRate(bankInfo, amount);
    } else {
      setConvertResult({ rate: 1, cost: 0, amount: amount || 0 });
      return;
    }
  }, [bankInfo, amount]);

  useEffect(() => {
    if (exhcangeType == FIXED) {
      form.setFieldsValue({ exchange_rate: convertResult?.rate });
    } else if (exhcangeType == FLOATING && amount && bankInfo) {
      _getExchangeRate(bankInfo, amount);
    }
  }, [exhcangeType]);

  useEffect(() => {
    if (exchangeRate) {
      convertWithFixedRate(amount);
    }
  }, [exchangeRate]);

  return (
    <Drawer width={900} open={true} closable={false} onClose={close}>
      <h2>Prepay</h2>
      <Divider />
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onChange={() => setWasChanged(true)}
        onFinish={onFinish}
        initialValues={{ payment: 'offline' }}
        autoComplete="off"
      >
        <Form.Item
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true }]}
          label="Customer"
          name="user_id"
        >
          <UserSyncSelect type="user" />
        </Form.Item>
        <Form.Item
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          name="bank"
          label="Payment method"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Select Bank Account"
            options={banks.map((v) => ({ label: v.name, value: v.id }))}
            onChange={(v) => setBank(v)}
          />
        </Form.Item>
        <Row>
          <Col span={10}>
            <Form.Item
              name="amount"
              label="Amount"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              rules={[{ required: true }]}
            >
              <InputNumber
                min={0}
                style={{ width: 150 }}
                prefix={bankInfo?.currency}
                onChange={(value: any) => setAmount(value)}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              name="exchange_type"
              label="Exchange Type"
              initialValue={0}
              rules={[{ required: true }]}
            >
              <Select>
                <Select.Option value={FLOATING}>
                  Floating Exchange Rate
                </Select.Option>
                <Select.Option value={FIXED}>Fixed Exchange Rate</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {bankInfo && bankInfo.currency !== 'USD' && (
          <Row>
            <Col span={8}>
              <Form.Item
                label="Currency"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <Input
                  readOnly
                  style={{ width: 150 }}
                  prefix="USD"
                  value={
                    convertResult
                      ? (((convertResult.amount * 100) << 0) / 100).toFixed(2)
                      : ''
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="exchange_rate"
                label="Exchange Rate"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <Input
                  readOnly={form.getFieldValue('exchange_type') == FLOATING}
                  style={{ width: 150 }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Exchange Loss: "
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
                <span className="ant-form-text">
                  {' '}
                  {convertResult ? convertResult.cost.toFixed(2) : ''}
                </span>
              </Form.Item>
            </Col>
          </Row>
        )}
        <Form.Item
          name="document"
          label="Screenshot"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 12 }}
          rules={[{ required: true }]}
          valuePropName="document"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e.fileList;
          }}
        >
          <Dragger
            beforeUpload={() => false}
            name="image"
            listType="picture"
            fileList={fileList}
            onChange={handleUpload}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Dragger>
        </Form.Item>
        <Divider />
        <Row>
          <Col offset={4} className="space">
            <Button type="default" onClick={close}>
              Cancel
            </Button>
            <Button
              type="primary"
              loading={submitting}
              onClick={() => form.submit()}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default PrepayOrderDetail;
