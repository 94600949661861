const kgToLb = (kg: number) => {
  return kg * 2.20462;
};

const cm3ToFt3 = (cm3: number) => {
  return cm3 * 0.00003531;
};

const in3ToFt3 = (in3: number) => {
  return in3 * 0.0005787;
};

export const freightClasses = [
  {
    threshold: 30,
    classCode: '60',
  },
  {
    threshold: 22.5,
    classCode: '65',
  },
  {
    threshold: 15,
    classCode: '70',
  },
  {
    threshold: 12,
    classCode: '85',
  },
  {
    threshold: 10,
    classCode: '92.5',
  },
  {
    threshold: 8,
    classCode: '100',
  },
  {
    threshold: 6,
    classCode: '125',
  },
  {
    threshold: 4,
    classCode: '175',
  },
  {
    threshold: 2,
    classCode: '250',
  },
  { threshold: 1, classCode: '300' },
  {
    threshold: 0,
    classCode: '400',
  },
];

export const imperialFreightClass = (lb: number, ft3: number) => {
  const density = lb / ft3;
  for (let i = 0; i < freightClasses.length; i++) {
    if (density >= freightClasses[i].threshold) {
      return freightClasses[i].classCode;
    }
  }

  return '400';
};

export enum MeasureType {
  Cargo = 'Cargo',
  Total = 'Total',
}

export enum UnitType {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export enum WeightUnit {
  LBS = 'lbs',
  KG = 'kg',
}

export enum DimensionUnit {
  IN = 'in',
  CM = 'cm',
}

export interface QuoteItem {
  class: string;
  package_type: string;
  height: number;
  length: number;
  pieces: number;
  total_weight: number;
  units: number;
  width: number;
  weight_unit: WeightUnit;
  dimension_unit: DimensionUnit;
}

export const calcFreightClass = (
  weight: number,
  length: number,
  width: number,
  height: number,
  units: number,
  weight_unit: WeightUnit,
  dimension_unit: DimensionUnit,
) => {
  const lb = weight_unit === WeightUnit.KG ? kgToLb(weight) : weight;
  const volume = length * width * height;
  const ft3PerUnit =
    dimension_unit === DimensionUnit.CM ? cm3ToFt3(volume) : in3ToFt3(volume);
  return imperialFreightClass(lb, units * ft3PerUnit);
};

export const genItemsWithClass = (items: any) => {
  return items.map((item: any) => ({
    ...item,
    class: calcFreightClass(
      item.total_weight,
      item.length,
      item.width,
      item.height,
      item.units,
      item.weight_unit,
      item.dimension_unit,
    ),
  }));
};

export const packageingTypes = [
  'Bag',
  'Bale',
  'Box',
  'Bucket',
  'Bundle',
  'Can',
  'Carton',
  'Case',
  'Coil',
  'Crate',
  'Cylinder',
  'Drums',
  'Pail',
  'Pieces',
  'Pallet',
  'Reel',
  'Roll',
  'Skid',
  'Tube',
  'Tote',
];

export const CARRIERS = [
  { scac: 'MHPG', name: 'Mothership' },
  { scac: 'ABFS', name: 'ABF' },
  { scac: 'CTBV', name: 'Custom Companies' },
  { scac: 'FWDN', name: 'Forward Air' },
  { scac: 'FCSY', name: 'Frontline' },
  { scac: 'ODFL', name: 'Old Dominion' },
  { scac: 'RDFS', name: 'Roadrunner' },
  { scac: 'SAIA', name: 'SAIA' },
  { scac: 'SEFL', name: 'Southeastern' },
  { scac: 'TFIN', name: 'TForce' },
  { scac: 'UPGF', name: 'UPS Freight' },
  { scac: 'WARD', name: 'Ward' },
  { scac: 'XPOL', name: 'XPO' },
  { scac: 'TPSS', name: 'TSA' },
  { scac: 'FLOK', name: 'Flockfreight' },
  { scac: 'LOAA', name: 'Loadsmart' },
  { scac: 'BTYT', name: 'Best Yet Express' },
  { scac: 'EXLA', name: 'Estes Express Lines' },
  { scac: '2DAY', name: '2Day Transportation' },
  { scac: 'TFIS', name: 'TFI International' },
  { scac: 'RLCA', name: 'R+L Carriers' },
  { scac: 'CTII', name: 'Central Transport' },
  { scac: 'AVRT', name: 'Averitt Express' },
  { scac: 'DAFG', name: 'Dayton Freight Lines' },
  { scac: 'PITD', name: 'Pitt Ohio' },
  { scac: 'AACT', name: 'AAA Cooper Transportation' },
  { scac: 'MULM', name: 'Mullen Group' },
  { scac: 'PYLE', name: 'A. Duie Pyle' },
  { scac: 'DAYR', name: 'Day & Ross Freight' },
  { scac: 'DYLT', name: 'Daylight Transport' },
  { scac: 'RRTS', name: 'Roadrunner Freight' },
  { scac: 'OAKH', name: 'Oak Harbor Freight Lines' },
  { scac: 'CFZB', name: 'FASTFRATE' },
  { scac: 'CCYQ', name: 'CrossCountry Freight Solutions' },
  { scac: 'MGUL', name: 'Magnum LTL' },
  { scac: 'MIDW', name: 'Midwest Motor Express' },
  { scac: 'LRGR', name: 'Landstar' },
  { scac: 'DPHE', name: 'Dependable Highway Express' },
  { scac: 'PUTB', name: 'Peninsula Truck Lines' },
  { scac: 'NOPK', name: 'North Park Transportation' },
  { scac: 'CACG', name: 'Canada Cartage' },
  { scac: 'ANMU', name: 'Anderson Trucking Service' },
  { scac: 'JPCU', name: 'JP Express' },
  { scac: 'MSWN', name: 'McCollister' },
  { scac: 'BIOS', name: 'Bison Transport' },
  { scac: 'FXFE', name: 'FedEx Freight' },
  { scac: 'TOTL', name: 'Total Transportation' },
  { scac: 'SFPA', name: 'Sunset Pacific Transportation' },
  { scac: 'GTJN', name: 'Go To Logistics' },
  { scac: 'MTVL', name: 'GLS US Freight' },
  { scac: 'WARW', name: 'White Arrow' },
  { scac: 'ONIX', name: 'Onix' },
  { scac: 'EWLV', name: 'EW Logistics' },
  { scac: 'MLTL', name: 'Merrylife' },
  { scac: 'SMLO', name: 'SM Logistics' },
  { scac: 'BKUG', name: 'Black Dragon' },
];

export const serviceTypes = ['Full', 'Partial'];

export const trailerTypes = [
  { name: '53_Foot_Dry_Van', key: '53ft' },
  { name: '48_Foot_Dry_Van', key: '48ft' },
  { name: 'Sprinter_Van', key: 'sprinter_van' },
  { name: 'Straight_Truck', key: 'straight_truck' },
  { name: 'Small_Straight_Truck', key: 'small_straight_truck' },
  { name: 'Other', key: 'other' },
];

export const pickupAccesorials = [
  { name: 'Inside Pickup', key: 'inside' },
  { name: 'Liftgate Pickup', key: 'liftgate' },
];

export const destinationAccesorials = [
  { name: 'Liftgate Delivery', key: 'liftgate' },
  { name: 'Appointment', key: 'appointment' },
  { name: 'Inside Delivery', key: 'inside' },
];

export const shipmentAccesorials = [
  { name: 'Hazardous materials', key: 'hazmat' },
];
